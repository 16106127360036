<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <h1>Pacientes Atendidos</h1>
                </div>
                <button
                  class="btn btn-light px-4 ml-3"
                  @click="goToBack"
                >
                  Regresar
                </button>
              </div>
              <div class="col-sm-3 offset-sm-4 d-flex justify-content-end align-items-center pt-2">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input
                      type="checkbox"
                      id="filter"
                      :checked="estado"
                      v-model="estado"
                      @change="listarPacientes"
                    >
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="por_pagina"
                    :options="pagina_opciones"
                    @change="listarPacientes"
                  >
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-4 pr-0">
                <multiselect
                  v-model="medicoSelected"
                  :options="medicos"
                  placeholder=""
                  label="nombre"
                  track-by="id"
                  select-label=""
                  deselect-label="X"
                  @select="selecMedico"
                >
                  <span slot="caret" v-if="!(medicoSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-2 text-right">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input
                      type="text"
                      class="form-control"
                      v-model="busqueda"
                      autocomplete="off"
                      placeholder="Buscar"
                      @keyup="filtrarBusqueda"
                    >
                  </div>
                </div>
              </div>
            </div>
            <b-table
              class="table table-striped table-hover"
              :fields="fieldsTabla"
              :items="listaTabla"
              :busy="isTableLoading"
              :per-page="por_pagina"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template #cell(editar)>
                <i
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                  :class="hasPermisionModificarConsultaExterna? 'text-success':''"
                ></i>
              </template>
              <template #cell(print)='items'>
                <i
                  class="nav-icon i-File-Download font-weight-bold"
                  :class="hasPermisionImprimirConsultaExterna? 'text-info':''"
                  style="cursor: pointer"
                  @click="openModalPrint(items.item)"
                >
                </i>
              </template>
              <template #cell(fecha_registro)="row">
                {{ formatDate(row.item.fecha_registro) }} <span style="color:blue"> {{ getTime(row.item.fecha_registro) }}</span>
              </template>
              <template #cell(fecha_reserva)="row">
                {{ formatDate(row.item.fecha_reserva) }} <span style="color:blue"> {{ getTime(row.item.fecha_reserva) }}</span>
              </template>
              <template #cell(consulta)="row">
                {{ formatDate(row.item.consulta) }} <span style="color:blue">{{ getTime(row.item.consulta) }}</span>
                <span
                  class="text-success font-weight-bold"
                >
                  {{row.item.turno_medico}}
                </span>
              </template>
              <template #cell(reconsulta)="row">
                {{row.item.reconsulta}}
              </template>
              <template #cell(seguro)="row">
                <i
                  v-if="row.item.seguro"
                  class="text-20 nav-icon i-ID-Card"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(pago)="row">
                <span
                  class="font-weight-bold"
                  :class="row.item.pago? 'text-success': 'text-danger'"
                >
                </span>
              </template>
            </b-table>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarPacientes"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalPrint :itemService="itemServicio" :selectedConsulta="selectedId"/>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import modalPrint from '../components/modalPrint.vue';

export default {
  name: 'atencionPacienteView',
  components: {
    Multiselect,
    modalPrint,
  },
  data() {
    return {
      busqueda: '',
      estado: true,
      isLoading: true,
      pagina_actual: 1,
      por_pagina: 100,
      pagina_opciones: [50, 100],
      delayTimeout: null,
      medicoSelected: null,
      medicos: [],
      isTableLoading: true,
      fieldsTabla: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'print', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N° C.E', thClass: 'th-custom-color', thStyle: { width: '6%' },
        },
        {
          key: 'vc', label: 'VC', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'fecha_registro', label: 'F/Registro', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'fecha_reserva', label: 'F/Reserva', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'paciente', label: 'Paciente', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '17%' },
        },
        {
          key: 'consulta', label: 'Consulta', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'reconsulta', label: 'Re Consulta', tdClass: 'text-12 px-0', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '13%' },
        },
        {
          key: 'seguro', label: 'Seguro', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '7%' },
        },
        {
          key: 'pago', label: 'Pago', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '7%' },
        },
      ],
      listaTabla: [
      ],
      totalFilas: 0,
      selectedId: null,
      selectedNro: null,
      itemServicio: null,
    };
  },
  created() {
    // this.isLoading = false;
    this.medicoSelected = {
      id: 0,
      nombre: 'TODOS',
    };
    this.isTableLoading = false;
    this.listarPacientes();
  },
  methods: {
    goToBack() {
      this.$router.push({ name: 'consulta-externa-index' });
    },
    async listarPacientes() {
      try {
        const REQUEST = {
          state: this.estado,
          filter_key: this.busqueda,
          page: this.pagina_actual,
          per_page: this.por_pagina,
          medico_id: this.medicoSelected ? this.medicoSelected.id : 0,
        };
        const RESPONSE = await axios.get('/clinic/consulta_externa/atencion_paciente/index', {
          params: REQUEST,
        });
        this.totalFilas = RESPONSE.data.data.atencion_paciente.total;
        this.listaTabla = RESPONSE.data.data.atencion_paciente.data;
        this.medicos = RESPONSE.data.data.medicos;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async downloadHistorialClinico() {
      console.log(this.selectedId);
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarPacientes, DELAY);
    },
    openModalPrint(item) {
      if (this.hasPermisionImprimirConsultaExterna) {
        this.selectedId = item;
        // eslint-disable-next-line no-unused-vars
        this.getServicioComplementario(item.consulta_id).then((e) => {
          this.$bvModal.show('modalPrintConsultaExterna');
        });
      }
    },
    updateSelectedId(value) {
      this.selectedId = value;
    },
    async getServicioComplementario(item) {
      try {
        const RESPONSE = await axios.get(`clinic/consulta_externa/${item}/get_categoria_servicio_complementarios`);
        this.itemServicio = RESPONSE.data.data.categoria_servicio_complementarios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    selecMedico(item) {
      this.medicoSelected = item;
      this.listarPacientes();
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/(\d{2}\/\d{2}\/\d{4})/);
      return match ? match[0] : '';
    },
    getTime(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/(\d{2}:\d{2})/);
      return match ? match[0] : '';
    },
  },
  computed: {
    ...mapGetters('main', [
      'getFuncionalidades',
      'isBloqueadoReConsulta',
      'hasPermisionListarConsultaExterna',
      'hasPermisionModificarConsultaExterna',
      'hasPermisionImprimirConsultaExterna',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
};
</script>

<style scoped>
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
